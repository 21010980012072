.react-datepicker__header {
    text-align: center;
    background-color: #6f6f6f;
    border-bottom: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
  
  }
  
  .react-datepicker__day.react-datepicker__day--010.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--today {
    background: #838282 !important;
   
  }

  .react-datepicker__day.react-datepicker__day--009.react-datepicker__day--keyboard-selected.react-datepicker__day--range-start.react-datepicker__day--in-range{
    background: #a9a8a8 !important;
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
}